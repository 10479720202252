import { api } from '../../constants/api.constant';
import { APIError } from '../../constants/errorCode/system';
import httpHelper from '../../helpers/httpHelper';
import BaseServices from '../base.services';

export default {
  list: async params => {
    try {
      console.log('credit-check-list', params);
      const query = params ? `?${httpHelper.serialize(params)}` : '';
      const url = api.creditCheckV2.list + query;
      console.log('credit-check-list', url);
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  search: async params => {
    try {
      const url = api.creditCheckV2.search;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  latest: async params => {
    try {
      const query = params ? `?${httpHelper.serialize(params)}` : '';
      const url = api.creditCheckV2.latest + query;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  download: async params => {
    try {
      const url = api.creditCheckV2.download;
      const data = await BaseServices.postDataToken(url, params);
      if (data.status === 200) {
        return await data.blob();
      }
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
